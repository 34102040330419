export default {
    // 登录信息
    setUserInfo: function (state, v) {
        state.userInfo = v;
    },

    //设置语言
    setLanguage: function (state, v) {
        state.language = v;
    },
    // 登录信息
    setToken: function (state, v) {
        state.token = v;
    },
    // 场站id
    setSiteId: function (state, v) {
        state.siteId = v;
    },
}
