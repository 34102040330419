export default {
	fontSize(res){
		const clientWidth = window.innerWidth||document.documentElement.clientWidth||document.body.clientWidth;
		if (!clientWidth) return;
		let fontSize = clientWidth / 1920;
		return res*fontSize;
	},
	timestampToTime_YMDhms (timestamp) {
        var regex = /[-:年月日时分秒]+/im;
        if (regex.test(timestamp)) {
            return timestamp;
        } else {
            var date = new Date();//时间戳为10位需*1000，时间戳为13位的话不需乘1000
            date.setTime(timestamp);
            var Y = date.getFullYear() + '-';
            var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
            var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
            var h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
            var m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
            var s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
            return Y + M + D + h + m + s;
        }
    },
    getDays7 () {
        let dates = [];
        // 获取当前日期
        let now = new Date();
        // 循环获取最近 7 天的日期
        for (let i = 0; i < 7; i++) {
            // 获取当前日期的时间戳
            let timestamp = now.getTime();

            // 计算 i 天前的时间戳
            let dayTimestamp = 24 * 60 * 60 * 1000; // 一天的毫秒数
            let iDayAgoTimestamp = timestamp - i * dayTimestamp;

            // 转换为日期对象
            let date = new Date(iDayAgoTimestamp);

            // 格式化日期为 "yyyy-MM-dd" 的字符串并存入数组
            let year = date.getFullYear();
            let month = ("0" + (date.getMonth() + 1)).slice(-2);
            let day = ("0" + date.getDate()).slice(-2);
            dates.push(year + "-" + month + "-" + day +" 23:59:00");
        }
        return dates
    }
}
