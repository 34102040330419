export default {
    userInfo:{   //登录信息
        comphone: "",
        operateaccount: "",
        operateaccountid: "",
        token: ""
    },
    language:'',
    token:'',
    siteId:'',
}


