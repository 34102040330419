<template>
    <div class="page">
        <pageHeader :child="curDate"></pageHeader>
        <div class="line">
            <img class="_left" src="./assets/images/line-left.png">
            <img class="_right" src="./assets/images/line-right.png">
            <img class="_bottom" src="./assets/images/line-bottom.png">
        </div>
        <div class="page-main">
            <div class="page-left">
                <leftTop :child="leftTopData"></leftTop>
                <leftBottom :child="leftBottomData"></leftBottom>
            </div>
            <div class="page-content">

                <!-- <plantAreaVideo :child="videoData"></plantAreaVideo> -->
            </div>
            <div class="page-right">
                <rightTop :child="rightTopData"></rightTop>
                <rightCenter :child="rightCenterData"></rightCenter>
                <rightBottom :child="rightBottomData"></rightBottom>
            </div>
        </div>
        <pageBg :child="centerData"></pageBg>
    </div>
</template>

<script>
// import plantAreaVideo from "@/components/video.vue";
import pageHeader from "@/components/pageHeader.vue";
import leftTop from "@/components/leftTop.vue";
import leftBottom from "@/components/leftBottom.vue";
import rightTop from "@/components/rightTop.vue";
import rightCenter from "@/components/rightCenter.vue";
import rightBottom from "@/components/rightBottom.vue";
import pageBg from "@/components/pageBg.vue";

import CountTo from "vue-count-to";

import {
    getToken,
    get_site,
    site_overview,
    ess_overview,
    solar_overview,
    reduction_overview,
    revenue_overview,
    evCharger_overview,
} from "@/api/index"


export default {
    name: "index",
    components: {
        CountTo,
        pageHeader,
        leftTop,
        leftBottom,
        rightTop,
        rightCenter,
        rightBottom,
        pageBg,
    },
    data() {
        return {
            language: 'zh',
            siteId:'',
            allData: {},
            curDate: '',//当前日期
            curData:{}, //当前日期数据
            history7Days:[],//历史7天日期
            history7Datas:[],//历史7天数据
            _i:0,//当前日期所在数组中的索引
            date:'',
            timer: null,  //定时请求
            dataTimer:null,
            leftTopData: {
                value1:0,
                value2:0,
                value3:0,
                value4:0,
                value5:0,
                value6:0,
                chartData:{ xAxis: ['7/1', '7/2', '7/3', '7/4', '7/5', '7/6', '7/7'],
                    data: [
                        {
                            name: '充电量',
                            list: [0,0,0,0,0,0,0]
                        },
                        {
                            name: '放电量',
                            list: [0,0,0,0,0,0,0]
                        },
                        
                    ]
                }
            },
            leftTopChartsData:{},
            leftBottomData: {
                value1:0,
                value2:0,
                value3:0,
                chartData: { xAxis: ['7/1', '7/2', '7/3', '7/4', '7/5', '7/6', '7/7'],
                    data: [
                        {
                            name: 'PR',
                            list: [0,0,0,0,0,0,0]
                        },
                        {
                            name: '发电量',
                            list: [0,0,0,0,0,0,0]
                        },
                        
                    ]
                },
            },
            leftBottomChartsData:{},
            rightTopData: {
                value1:0,
                value2:0,
                value3:0,
            },
            rightCenterData: {
                value1:0,
                value2:0,
                value3:0,
                chartData:[
                    {id:'xftg',name:'',value:0},//削峰填谷
                    {id:'jxkz',name:'',value:0},//降需控制
                    {id:'gffd',name:'',value:0},//光伏发电
                ]
            },
            rightBottomData: {
                value1:0,
                value2:0,
                value3:0,
                chartData:{ xAxis: ['7/1', '7/2', '7/3', '7/4', '7/5', '7/6', '7/7'],
                    data: [
                        {
                            name: '充电量',
                            list: [0,0,0,0,0,0,0]
                        },
                        {
                            name: '放电量',
                            list: [0,0,0,0,0,0,0]
                        },
                        
                    ]
                }
            },
            rightBottomChartsData:{},
            centerData: {
                value1:0,  //光伏装机容量
                value2:0,  //光伏实时功率
                value3:101,  //储能容量
                value3_1:110,  //储能容量
                value4:0,  //实时功率
                value5:0,  //SOC
                value6:0,  //充电桩总功率
                value7:0,  //车位状态 （充电中/总数）
            },
            fullscreen:false,
        };
    },
    computed: {},
    created() {
        this.language = this.$store.state.language;
        clearInterval(this.dataTimer)
        clearInterval(this.timer)
    },
    destroyed() {
    },
    mounted() {
        this.init();
        getToken().then(res => {
            if(res.code == 10000){
                this.$store.commit("setToken",res.data.token)
                
                let tok = this.$store.state.token;
                // 场站列表
                get_site().then(resp => {
                    if(resp.code == 10000){
                        this.$store.commit("setSiteId",resp.data.siteId)
                        this.getData();

                        this.dataTimer = setInterval(() => {
                            this.getData();
                        }, 120000);
                    }
                })
                .catch(res => {
                
                })

            }
        })
        .catch(res => {
        
        })

        
    },
    methods: {
        filterData(val){
            let v = 0;
            if(val < 10000){
                v = val + 'kWh'
            }else if(val < 10000000){
                v = (val / 1000).toFixed(2) + 'MWh';
            }else{
                v = (val / 1000000).toFixed(2) + 'GWh';
            }
            return v;
        },
        getData(){
            // 场站概况
            site_overview().then(res => {
                // console.log("场站概况",res)
                if(res.code == 10000){
                    this.centerData = {
                        value1:res.data.solar.capacity,  //光伏装机容量
                        value2:res.data.solar.activePower, //光伏实时功率
                        value3:res.data.storage.capacity,  //储能容量
                        value3_1:res.data.storage.ratedCapacity,  //储能容量
                        value4:res.data.storage.activePower,   //实时功率
                        value5:res.data.storage.soc,  //SOC
                        value6:res.data.evCharger.totalPower,  //充电桩总功率
                        value7:res.data.evCharger.chargeCount+'/'+res.data.evCharger.totalCount,  //车位状态 （充电中/总数）
                    }
                }
            })
            .catch(res => {
                
            })

            /**
             * 储能概况
             * **/ 
            ess_overview().then(res => {
                // console.log("储能概况",res)
                if(res.code == 10000){
                    this.leftTopData = {
                        value1:res.data.dailyCharging,  //今日充电量
                        value2:res.data.dailyDischarging,  //今日放电量
                        value3:res.data.monthlyCharging,  //本月充电量
                        value4:res.data.monthlyDischarging,  //本月放电量
                        value5:res.data.totalCharging,  //累计充电量
                        value6:res.data.totalDischarging,  //累计放电量
                        chartData: res.data.list,
                    }
                }
            })
            .catch(res => {
            
            })

            /**
             * 光伏概况
             * **/ 
            solar_overview().then(res => {
                // console.log("光伏概况",res)
                if(res.code == 10000){
                    this.leftBottomData = {
                        value1:res.data.dailyProduction,   //今日发电量
                        value2:res.data.monthlyProduction,   //本月发电量
                        value3:res.data.totalProduction,   //累计发电量
                        chartData: res.data.list,
                    }
                }
            })
            .catch(res => {
            
            })

            /**
             * 节能减排
             * **/ 
            reduction_overview().then(res => {
                // console.log("节能减排",res)
                if(res.code == 10000){
                    this.rightTopData = {
                        value1:res.data.co2Reduction,   //累计减排
                        value2:res.data.equivalentTrees,   //等效植树
                        value3:res.data.greenPowerPCT,   //绿电占比
                    }
                }
            })
            .catch(res => {
            
            })

            /**
             * 收益概况
             * **/ 
            revenue_overview().then(res => {
                // console.log("收益概况",res)
                if(res.code == 10000){
                    this.rightCenterData = {
                        value1:res.data.dailyRevenue,   //今日收益
                        value2:res.data.monthlyRevenue,   //本月收益
                        value3:res.data.totalRevenue,   //累计收益
                        chartData:[
                            {id:'xftg',name:'',value:res.data.arbitrage},//削峰填谷
                            {id:'jxkz',name:'',value:res.data.demandPeakShaving},//降需控制
                            {id:'gffd',name:'',value:res.data.solar},//光伏发电
                        ],
                    }
                }
            })
            .catch(res => {
            
            })

            /**
             * 充电桩概况
             * **/ 
            evCharger_overview().then(res => {
                // console.log("充电桩概况",res)
                if(res.code == 10000){
                    this.rightBottomData = {
                        value1:res.data.dailyCharging,  //今日充电量c
                        value2:res.data.monthlyCharging,  //本月充电量c
                        value3:res.data.totalCharging,  //累计充电量c
                        chartData: res.data.list,
                    }
                }
            })
            .catch(res => {
            
            })
        },
        init(){
            let that = this;
            let prev = '',
                time = '';
            
            that.curDate = this.$utils.timestampToTime_YMDhms((new Date()).getTime())
            clearInterval(this.timer)
            this.timer = setInterval(() => {
                that.curDate = this.$utils.timestampToTime_YMDhms((new Date()).getTime())
            },1000)
        },

    },
};
</script>

<style lang="scss">
/**框架**/
.page {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    // background: url("./assets/images/bg.jpg") no-repeat;
    // background-size: 100% 100%;
    // background-color: #70798a;

    > .line{
        position: fixed;
        top:30px;
        bottom:0%;
        width:100%;
        z-index: 2;

        ._left,._right{
            width:205px;
            height:100%;
            position: absolute;
            top:0;
        }
        ._left{
            left:0;
        }
        ._right{
            right:0;
        }
        ._bottom{
            position: absolute;
            left:50%;
            bottom:11px;
            width:1540px;
            height:14px;
            margin-left:-770px; 
        }
        
    }

    .page-main {
        flex:1;
        display: flex;
        justify-content: space-between;
        position: relative;
        margin:100px 32px 32px;
        z-index: 2;
        overflow: hidden;

        .page-left{
            display: flex;
            flex-direction: column;
            width:442px;
        }

        .page-right{
            display: flex;
            flex-direction: column;
            width:440px;
        }
    }

}

@keyframes myfirst
{
    from {opacity: 1;}
    to {opacity: 0;}
}

</style>
