<template>
    <div class="page-header">
        <img v-show="language == 'zh'" :class="language == 'zh' ?'title':'title'" src="../assets/images/title-cn.png">
        <img v-show="language == 'en'" :class="language == 'en' ?'title en':'title'" src="../assets/images/title-en.png">
        <div class="_left">
            <img class="logo1" src="../assets/images/logo_01.png" alt="">
            <img class="logo2" src="../assets/images/logo_02.png" alt="">
        </div>
        <div class="_right">
            <div class="language">
                <span :class="language == 'zh' ? 'cur' : ''" @click="setLanguage('zh')">中文简体</span>
                <span :class="language == 'en' ? 'cur' : ''" @click="setLanguage('en')">English</span>
            </div>
            <div class="time">{{ time }}</div>
        </div>
    </div>
</template>

<script>
export default {
    props: ["child"],
    data() {
        return {
            language: this.$store.state.language,
            time:'',
            timer:null
        };
    },
    created() {

    },
    components:{

    },
    mounted() {
        this.language = this.$store.state.language;
    },
    methods: {
        setLanguage(n){
            this.language = n;
            this.$i18n.locale = n;
            this.$store.commit("setLanguage", this.language);
        },
    },
    watch: {
        "$store.state.language": function (newLang) {
            this.language = newLang;
        },
        child: {
            handler: function (newValue) {
                this.time = newValue;
            },
            deep: true,
        },
    }
};
</script>

<style scoped lang='scss'>
.page-header{
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100px;
    min-height:100px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    z-index: 3;
    padding-bottom:10px;
    background: url("../assets/images/bg_head.png") no-repeat;
    background-size: 100% 100%;

    .title{
        position:absolute;
        top:50%;
        left:50%;
        width:306px;
        height:29px;
        transform: translate(-153px,-15px);

        &.en{
            width:509px;
            height:20px;
            transform: translate(-254px,-10px);
        }
    }

    ._left{ 
        margin-left:41px;
        .logo1{
            width:180px;
            height:30px;
        }
        .logo2{
            width:122px;
            height:30px;
            margin-left:45px;
        }
    }
    ._right{
        margin-right:41px;
        display: flex;
        .language{
            span{
                font-size:14px;
                color:#99ADBF;
                margin-left:26px;
                cursor: pointer;
                &.cur{
                    color:#DFE9F3;
                    font-weight: bold;
                    position: relative;

                    &::before{
                        content: '';
                        display: inline-block;
                        position: absolute;
                        width:43px;
                        height:34px;
                        bottom:-25px;
                        left:50%;
                        margin-left:-20px;
                        background: url("../assets/images/btn_s_01.png") no-repeat;
                        background-size: 100% 100%;
                    }
                }
            }
        }

        .time{
            font-size:16px;
            color:#DFE9F3;
            margin-left:108px;
        }
    }
    
}

</style>
