// 单位转换,返回数值
export function unitConvert_val (val) {
    let v = 0;
    if(val < 10000){
        v = val
    }else if(val < 10000000){
        v = parseFloat((val / 1000).toFixed(2))
    }else{
        v = parseFloat((val / 1000000).toFixed(2))
    }
    return v;
}

// 单位转换,返回单位
export function unitConvert_unit (val) {
    let unit = 0;
    if(val < 10000){
        unit = 'kWh'
    }else if(val < 10000000){
        unit = 'MWh';
    }else{
        unit = 'GWh';
    }
    return unit;
}