import Vue from "vue";
import App from "./App.vue";
import store from "./store/";
import utils from "./config/utils.js";
import * as filters from './filters/index'
// import Highcharts from 'highcharts'//必须引入
import Highcharts from "highcharts/highcharts";
import highcharts3d from "highcharts/highcharts-3d";
highcharts3d(Highcharts);

import "./assets/css/base.css";
import "./assets/css/common.scss";

import "lib-flexible";

// import "./mock/index.js"

import VueI18n from "vue-i18n";
Vue.use(VueI18n);


Object.keys(filters).forEach(key => {
    Vue.filter(key, filters[key])
})


//多语言实例
//获取iframe 多语言
const getIframeQueryString = (name) => {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
    const iframe = window.parent.document.getElementsByTagName("iframe")[0];
    if (!iframe) {
        return null;
    }
    var r = iframe.contentWindow.location.search.substr(1).match(reg);
    if (r != null) {
        return decodeURI(r[2]);
    }
    return null;
};

let localeS = "zh";
const searchLocale = getIframeQueryString("locale");
if (searchLocale) {
    localeS = searchLocale === "zh-CN" ? "zh" : "en";
}

//多语言实例
const i18n = new VueI18n({
    locale: (function () {
        if (localStorage.getItem("lang")) {
            return localStorage.getItem("lang");
        }
        store.commit("setLanguage", localeS);
        return localeS;
    })(),
    messages: {
        en: require("./i18n/config/en.json"), //英文语言包
        zh: require("./i18n/config/zh.json"), //中文繁体包
    },
});

//VUEX
Vue.prototype.$store = store;
Vue.prototype.$utils = utils;

Vue.config.productionTip = false;

// 引入echarts
import * as echarts from "echarts";
Vue.prototype.$echarts = echarts;
Vue.prototype.$HighCharts = Highcharts;

new Vue({
    i18n,
    render: (h) => h(App),
}).$mount("#app");
