<template>
    <div class="page-bg">
        <div class="item item1">
            <div class="title">{{ $t("centerData[0].title") }}</div>
            <div class="ul">
                <div class="li">
                    <span class="left">{{ $t("centerData[0].text1") }}</span>
                    <div class="right">
                        <CountTo class="value" :startVal="0" :endVal="value1" :duration="2000"/>
                        <span class="unit">kWp</span>
                    </div>
                </div>
                <div class="li">
                    <span class="left">{{ $t("centerData[0].text2") }}</span>
                    <div class="right">
                        <CountTo class="value" :startVal="0" :endVal="value2" :duration="2000" :decimals="2"/>
                        <span class="unit">kW</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="item item2">
            <div class="title">{{ $t("centerData[1].title") }}</div>
            <div class="ul">
                <div class="li">
                    <span class="left">{{ $t("centerData[1].text1") }}</span>
                    <div class="right">
                        <CountTo class="value" :startVal="0" :endVal="value3" :duration="2000"/>
                        <span class="unit _t">kW</span>
                        <span class="value">/</span>
                        <CountTo class="value" :startVal="0" :endVal="value3_1" :duration="2000"/>
                        <span class="unit">kWh</span>
                    </div>
                </div>
                <div class="li">
                    <span class="left">{{ $t("centerData[1].text2") }}</span>
                    <div class="right">
                        <CountTo class="value" :startVal="0" :endVal="value4" :duration="2000" :decimals="2"/>
                        <span class="unit">kW</span>
                    </div>
                </div>
                <div class="li">
                    <span class="left">{{ $t("centerData[1].text3") }}</span>
                    <div class="right">
                        <CountTo class="value" :startVal="0" :endVal="value5" :duration="2000" :decimals="2"/>
                        <span class="unit">%</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="item item3">
            <div class="title">{{ $t("centerData[2].title") }}</div>
            <div class="ul">
                <div class="li">
                    <span class="left">{{ $t("centerData[2].text1") }}</span>
                    <div class="right">
                        <CountTo class="value" :startVal="0" :endVal="value6" :duration="2000" :decimals="2"/>
                        <span class="unit">kW</span>
                    </div>
                </div>
                <div :class="language == 'zh'?'li':'li en'">
                    <span class="left">{{ $t("centerData[2].text2") }}<br/>{{ $t("centerData[2].text3") }}</span>
                    <div class="right">
                        <!-- <CountTo class="value" :startVal="0" :endVal="value7" :duration="2000" :decimals="2"/> -->
                        <span class="value">{{ value7 }}</span>
                        <span class="unit"></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CountTo from "vue-count-to";
export default {
    props: ["child"],
    data() {
        return {
            chart:null,
            value1:0,  //光伏装机容量
            value2:0,  //光伏实时功率
            value3:0,  //储能容量
            value3_1:0,  //储能容量
            value4:0,  //实时功率
            value5:0,  //SOC
            value6:0,  //充电桩总功率
            value7:0,  //车位状态 （充电中/总数）
            language: this.$store.state.language,
        };
    },
    created() {},
    components: {
        CountTo,
    },
    mounted() {},
    methods: {},
    watch: {
        "$store.state.language": function (newLang) {
            this.language = newLang;
        },
        child: {
            handler: function (newValue) {
                this.chartData = newValue;
                this.$nextTick(() => {
                    this.value1 = parseFloat(newValue.value1);
                    this.value2 = parseFloat(newValue.value2);
                    this.value3 = parseFloat(newValue.value3);
                    this.value3_1 = parseFloat(newValue.value3_1);
                    this.value4 = parseFloat(newValue.value4);
                    this.value5 = parseFloat(newValue.value5);
                    this.value6 = parseFloat(newValue.value6);
                    this.value7 = newValue.value7;
                });
            },
            deep: true,
        },
    },
};
</script>

<style scoped lang="scss">
.page-bg {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url("../assets/images/bg.png") no-repeat;
    background-size: 100% 100%;

    .item {
        position: absolute;

        .title {
            font-size: 16px;
            color: #dfe7f2;
            position: absolute;
            top: 26%;
            left: 22px;

            &::before {
                content: "";
                display: inline-block;
                width: 70px;
                height: 8px;
                position: absolute;
                bottom: -10px;
                left: 50%;
                margin-left: -30px;
                opacity: 0.8;
                background: url("../assets/images/img_guang.png") no-repeat;
                background-size: 100% 100%;
            }
        }

        .ul{
            position: absolute;
            top:37%;
            left:22px;

            .li{
                width:100%;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                flex-wrap: nowrap;
                margin-top:8px;
                .left{
                    // width:155px;
                    font-size:14px;
                    color:#DFE9F3;
                }
                .right{
                    .value{
                        @include textGradient;
                        font-weight: bold;
                        font-size:18px;
                    }
                    .unit{
                        font-size:14px;
                        color:#99ADBF;
                        display: inline-block;
                        width:40px;

                        &._t{
                            width:auto;
                        }
                    }
                }
            }
        }

        &.item1 {
            width: 426px;
            height: 216px;
            top: 17%;
            left: 26.2%;
            background: url("../assets/images/center01.png") no-repeat;
            background-size: 100% 100%;
            .ul{
                width:230px;

                .li{
                    .left{
                        // width:140px;
                    }
                }
            }
        }

        &.item2 {
            width: 476px;
            height: 216px;
            top: 51%;
            left: 48.8%;
            background: url("../assets/images/center02.png") no-repeat;
            background-size: 100% 100%;
            .title {
                top:20%;
            }
            .ul{
                top:31%;
                left:22px;
                width:270px;
                .li{
                    .left{
                        // width:120px;
                    }
                }
            }
        }
        &.item3 {
            top:79%;
            left:40.8%;
            width: 476px;
            height: 216px;
            // margin-left: -178px;
            // margin-top: 15%;
            background: url("../assets/images/center03.png") no-repeat;
            background-size: 100% 100%;
            .title {
                top:36%;
            }
            .ul{
                top:46%;
                left:22px;
                width:270px;

                .li{

                    &:last-child{
                        // margin-top:20px;
                        .left{
                            width:120px;
                            text-align: center;
                            transform: translateX(-20px);
                        }
                    }

                    &.en{
                        &:last-child{
                            // margin-top:20px;
                            .left{
                                transform: translateX(-10px);
                            }
                        }
                    }
                    .left{
                        
                        line-height: 20px;
                        word-break: break-all;
                        line-break:anywhere;
                    }
                }
            }
        }
    }
}
</style>
