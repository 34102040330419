<template>
    <div class="page-item">
        <div class="_bg"></div>
        <div class="item-title right">
            <div class="_left"></div>
            <div class="_right">{{ $t("rightTopData.title") }}</div>
        </div>
        <div class="item-content">
            <div class="item">
                <div class="top"><CountTo class="value" :startVal='0' :endVal='value1' :duration="2000" :decimals="2" /></div>
                <div class="name">{{ $t("rightTopData.legend[0]") }}<span v-if="language == 'zh'" class="unit">{{ $t("rightTopData.unit[0]") }}</span></div>
                <div v-if="language == 'en'" class="unit">{{ $t("rightTopData.unit[0]") }}</div>
            </div>
            <div class="item">
                <div class="top"><CountTo class="value" :startVal='0' :endVal='value2' :duration="2000"/></div>
                <div class="name">{{ $t("rightTopData.legend[1]") }}<span v-if="language == 'zh'" class="unit">{{ $t("rightTopData.unit[1]") }}</span></div>
                <div v-if="language == 'en'" class="unit">{{ $t("rightTopData.unit[1]") }}</div>
            </div>
            <div class="item">
                <div class="top"><CountTo class="value" :startVal='0' :endVal='value3' :duration="2000" :decimals="2" /></div>
                <div class="name">{{ $t("rightTopData.legend[2]") }}<span v-if="language == 'zh'" class="unit">{{ $t("rightTopData.unit[2]") }}</span></div>
                <div v-if="language == 'en'" class="unit">{{ $t("rightTopData.unit[2]") }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import CountTo from "vue-count-to";

export default {
    props: ["child"],
    data() {
        return {
            chart:null,
            value1:0,
            value2:0,
            value3:0,
            colors: ["#00a5db", "#0969d5", "#6750ee", "#a44eed"],
            colorsTop: [
                "rgba(33,156,124,0.3)",
                "rgba(13,86,231,0.3)",
                "rgba(242,182,77,0.3)",
            ],
            language: this.$store.state.language,
            result: 0,
        };
    },
    created() { },
    components: {
        CountTo,
    },
    mounted() {
    },
    filters: {

    },
    methods: {
        unitFormat() {

        },
        init() {
            let that = this;

        },
    },
    watch: {
        "$store.state.language": function (newLang) {
            this.language = newLang;
        },
        child: {
            handler: function (newValue) {
                this.$nextTick(() => {
                    // this.init();
                    this.value1 = parseFloat(newValue.value1);
                    this.value2 = parseFloat(newValue.value2);
                    this.value3 = parseFloat(newValue.value3);
                });
            },
            deep: true,
        },
    },
};
</script>

<style scoped lang="scss">
.page-item{
    height: 176px;
    min-height: 176px;
    background:url('../assets/images/item-bg3.png') no-repeat;
    background-size: 100% 100%;
    align-items:flex-end;

    .item-title{
        width:calc(100% - 40px);
    }

    .item-content {
        display: flex;
        flex-direction: row;
        position: relative;
        margin-bottom: 0 !important;
        justify-content: space-between;
        width:calc(100% - 40px);
        margin-top:0;

        

        .item{
            flex:1;
            display: flex;
            flex-direction: column;
            .top{
                height:60px;
                background: url("../assets/images/bg_icon_01.png") no-repeat center center;
                background-size: 60px 60px;
                text-align: center;

                .value{
                    line-height: 60px;
                    text-align: center;
                    font-size:20px;
                    font-weight: bold;
                    @include textGradient;
                }
            }
            .name{
                font-size:14px;
                color:#EBF3FF;
                text-align: center;
                margin-top:5px;

                .unit{
                    text-align: center;
                    font-size:14px;
                    color:#99ADBF;
                    margin-top:5px;
                }
            }
            .unit{
                text-align: center;
                font-size:14px;
                color:#99ADBF;
                margin-top:5px;
            }
        }
    }
}

</style>
