import axios from 'axios' //引入axios
import store from "../store/index";
//下面这两个不一定需要引入，看你项目需要拦截的时候做什么操作，但是一般都需要引入store
// import router from '@/router'


let instance = axios.create({
  timeout: 10000,
  baseURL: '/api',
//   baseURL: '/',
  // baseURL: '/jiangyin2.5d-dashboard-api/',
  headers: {
    'Content-Type': 'application/json'
  },
  //下载进度
  onDownloadProgress: function (progressEvent) {
    // console.log(progressEvent);
  },
})
//编辑请求拦截
// http request 拦截器   发送请求前可以做出判断
instance.interceptors.request.use(
  config => {
    // 配置请求头
    config.headers['Authorization'] = 'Bearer ' + store.state.token; //'Bearer ' + store.state.user.token;
    // get请求映射params参数  gei请求在params里传对象格式即可
    if (config.method === 'get' && config.params || config.method === 'delete' && config.params) {
      let url = config.url + '?';
      for (const propName of Object.keys(config.params)) {
        const value = config.params[propName];
        var part = encodeURIComponent(propName) + "=";
        if (value !== null && typeof (value) !== "undefined") {
          if (typeof value === 'object') {
            for (const key of Object.keys(value)) {
              let params = propName + '[' + key + ']';
              var subPart = encodeURIComponent(params) + "=";
              url += subPart + encodeURIComponent(value[key]) + "&";
            }
          } else {
            url += part + encodeURIComponent(value) + "&";
          }
        }
      }
      url = url.slice(0, -1);
      config.params = {};
      config.url = url;
    }
    return config
  },
  err => {
    return Promise.reject(err)
  })


//编辑响应拦截
// http response 拦截器
//响应请求可以统一做出处理
instance.interceptors.response.use(
  (res) => {
    if (res.status === 200) {
      return res.data
    }

    // if (res.code === 0) {
    //     return res.data
    // }else{
    //     Message({
    //         message: res.msg,
    //         type: "error"
    //     })
    // }
  },
  (error) => {
    return Promise.reject(error)
  }
)

export default instance
