<template>
    <div class="page-item">
        <div class="_bg"></div>
        <div class="item-title right">
            <div class="_left"></div>
            <div class="_right">{{ $t("rightCenterData.title") }}</div>
        </div>
        <div class="item-content">
            <div :class="language == 'zh' ? 'item-info' : 'item-info en'">
                <div class="_top">
                    <div class="item">
                        <CountTo class="value" :startVal='0' :endVal='value1' :duration="2000" :decimals="2" />
                        <div class="unit">{{ $t("rightCenterData.unit1") }}</div>
                        <div class="name">{{ $t("rightCenterData.text1") }}</div>
                    </div>
                    <div class="item">
                        <CountTo class="value" :startVal='0' :endVal='value2' :duration="2000"/>
                        <div class="unit">{{ $t("rightCenterData.unit2") }}</div>
                        <div class="name">{{ $t("rightCenterData.text2") }}</div>
                    </div>
                    <div class="item">
                        <CountTo v-show="language == 'zh'" class="value" :startVal='0' :endVal='value3/10000' :duration="2000" :decimals="2"/>
                        <CountTo v-show="language == 'en'" class="value" :startVal='0' :endVal='value3/1000' :duration="2000" :decimals="2"/>
                        <div class="unit">{{ $t("rightCenterData.unit3") }}</div>
                        <div class="name">{{ $t("rightCenterData.text3") }}</div>
                    </div>
                </div>
            </div>
            <div class="_bottom">
                <div class="legend">
                    <div class="item" v-for="(item,index) in chartData" :key="index">
                        <div class="name"><i :style="{background:color[index]}"></i>{{ $t("rightCenterData.chartLegend["+index+"]") }}</div>
                        <div class="value">{{ (item.value * 100).toFixed(1) }}<i>%</i></div>
                    </div>
                </div>
                <div class="chart" ref="echarts"></div>
            </div>
           
        </div>
    </div>
</template>

<script>
import CountTo from "vue-count-to";

export default {
    props: ["child"],
    data() {
        return {
            chart:null,
            value1:0,
            value2:0,
            value3:0,
            color: ["#5460D7", "#007AFC","#1A8379","#AF8934"],
            colorTop: ["#5360d2", "#0078fa", "#1e968c", "#c19d3f"],
            colorBottom: ["#535fd5", "#0566d3", "#186961", "#996e24"],
            chartData:[],
            language: this.$store.state.language,
            legend:this.$t("rightCenterData.chartLegend")
        };
    },
    created() { },
    components: {
        CountTo,
    },
    mounted() {
        // this.$nextTick(() => {
        //     setTimeout(() => {
        //         this.chart ? this.chart.resize() : "";
        //         this.chart ? this.chart.clear() : "";
        //         this.init();
        //     }, 200);
        // });
        window.addEventListener("resize", () => {
            setTimeout(() => {
                this.chart ? this.chart.resize() : "";
                this.chart ? this.chart.clear() : "";
                this.init();
            }, 300);
        });
    },
    filters: {

    },
    methods: {
        init() {
            let that = this;
            this.chart = this.$echarts.init(this.$refs.echarts);
            let index = 0, allNum = 0;
            let data = JSON.parse(JSON.stringify(that.chartData))
            data.forEach((item, index) => {
                allNum += parseFloat(item.value)
            })
            let arr = [];
            for (let i = 0 ; i < data.length; i++) {
                let name = ''
                arr.push(
                    {
                        name:that.legend[i],
                        value: data[i].value,
                        itemStyle: {
                            color: {
                                type: 'linear',
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [{
                                    offset: 0, color: that.colorTop[i] // 0% 处的颜色
                                }, {
                                    offset: 1, color: that.colorBottom[i] // 100% 处的颜色
                                }],
                                global: false // 缺省为 false
                            },
                        }
                    },
                    { name: 'a' + i, value: allNum/100, itemStyle: { color: 'transparent' }, emphasis: { label: { show: false } } },
                )
            }

            let option = {
                title:{
                    text: that.language == 'zh'?'成本节省':"Cost Saving",
                    top: '44%',
                    left: that.language == 'zh'?'26%':"18%",
                    textStyle: {
                        color: '#DFE9F3',
                        fontSize: that.$utils.fontSize(12),
                        // fontWeight: 'bold',
                        width:'100%',
                        textAlign: 'center'
                    },
                },
                series: [
                    {
                        name: 'Access From',
                        type: 'pie',
                        center: ['50%', '50%'],
                        radius: ['58%', '72%'],
                        avoidLabelOverlap: false,
                        label: {
                            show: false,
                            position: 'center'
                        },
                        emphasis: {
                            label: {
                                show: false,
                                fontSize: 40,
                                fontWeight: 'bold',
                                formatter: function (value, index) {
                                    return "{a|" + value.value + "}{b|%}"
                                },
                                rich: {
                                    a: {
                                        color: '#DFE9F3',
                                        fontSize: that.$utils.fontSize(18),
                                        fontFamily: 'SourceHanSansCN-Regular',
                                        fontWeight: "bold"
                                    },
                                    b: {
                                        color: '#99ADBF',
                                        fontSize: that.$utils.fontSize(12),
                                        fontFamily: 'SourceHanSansCN-Regular',
                                        verticalAlign: 'bottom',
                                        padding: [0, 0, 0, that.$utils.fontSize(6)],
                                        align: 'left'
                                    },
                                    c: {
                                        color: '#5a6272',
                                        fontSize: that.$utils.fontSize(22),
                                        fontFamily: 'SourceHanSansCN-Regular',
                                        padding: [that.$utils.fontSize(10), 0, 0, 0],
                                    },
                                },
                            }
                        },
                        labelLine: {
                            show: false
                        },
                        itemStyle: {
                            // borderWidth:4,
                            // borderColor:'#151621'
                        },
                        data: arr
                    }
                ]
            };
            that.chart.setOption(option, true);

            // that.handleChartLoop(option, that.chart);//定义名称

        },
        handleChartLoop(option, myChart) {
            if (!myChart) {
                return
            }
            let time = 10000;
            let currentIndex = -1 // 当前高亮图形在饼图数据中的下标
            let changePieInterval = null // 设置自动切换高亮图形的定时器
            // let changePieInterval = setInterval(selectPie, time) // 设置自动切换高亮图形的定时器

            // 取消所有高亮并高亮当前图形
            function highlightPie() {
                // 遍历饼图数据，取消所有图形的高亮效果
                for (var idx in option.series[0].data) {
                    myChart.dispatchAction({
                        type: 'downplay',
                        seriesIndex: 0,
                        dataIndex: idx
                    })
                }
                // 高亮当前图形
                myChart.dispatchAction({
                    type: 'highlight',
                    seriesIndex: 0,
                    dataIndex: currentIndex
                })
            }

            // 用户鼠标悬浮到某一图形时，停止自动切换并高亮鼠标悬浮的图形
            myChart.on('mouseover', (params) => {
                // clearInterval(changePieInterval)
                currentIndex = params.dataIndex
                highlightPie()
            })

            // 用户鼠标移出时，重新开始自动切换
            myChart.on('mouseout', (params) => {
                if (changePieInterval) {
                    // clearInterval(changePieInterval)
                }
                // changePieInterval = setInterval(selectPie, time)
            })

            // 高亮效果切换到下一个图形
            function selectPie() {
                var dataLen = option.series[0].data.length
                currentIndex = (currentIndex + 1) % dataLen
                highlightPie()
            }
            selectPie();
        }
    },
    watch: {
        "$store.state.language": function (newLang) {
            this.language = newLang;
            this.legend = this.$t("leftTopData.chartLegend")
            this.$nextTick(() => {
                this.init();
            });
        },
        child: {
            handler: function (newValue) {
                this.$nextTick(() => {
                    setTimeout(() => {
                        this.value1 = parseFloat(newValue.value1);
                        this.value2 = parseFloat(newValue.value2);
                        this.value3 = parseFloat(newValue.value3);
                        this.chartData = newValue.chartData;
                        this.init();
                    }, 200);
                });
            },
            deep: true,
        },
    },
};
</script>

<style scoped lang="scss">
.page-item{
    height: 326px;
    min-height: 326px;
    background:url('../assets/images/item-bg4.png') no-repeat;
    background-size: 100% 100%;

    .item-content {
        display: flex;
        flex-direction: column;
        position: relative;
        margin-bottom: 0 !important;
        margin-top: 0 !important;
        overflow: hidden;

        ._top{
            flex:1;
            margin-top:20px;
            border-bottom:none !important;
        }
        ._bottom{
            height:60%;
            display: flex;

            .legend{
                flex:1;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                padding:40px 10px;

                .item{
                    display: flex;
                    justify-content: space-between;

                    .name{
                        font-size:14px;
                        color:#DFE9F3;

                        > i{
                            display: inline-block;
                            width:10px;
                            height:10px;
                            margin-right:10px;
                        }
                    }
                    .value{
                        font-size:18px;
                        color:#DFE9F3;

                        > i{
                            color:#99ADBF;
                            font-size:12px;
                            margin-left:4px;
                        }
                    }
                }
            }

            .chart{
                width:32%;
            }
        }
    }
}

</style>
