<template>
    <div class="page-item">
        <div class="_bg"></div>
        <div class="item-title right">
            <div class="_left"></div>
            <div class="_right">{{ $t("rightBottomData.title") }}</div>
        </div>
        <div class="item-content">
            <div :class="language == 'zh' ? 'item-info' : 'item-info en'">
                <div class="_top">
                    <div class="item">
                        <CountTo class="value" :startVal='0' :endVal='value1 | unitConvert_val' :duration="2000" :decimals="2" />
                        <div class="unit">{{value1 | unitConvert_unit}}</div>
                        <div class="name">{{ $t("rightBottomData.text1") }}</div>
                    </div>
                    <div class="item">
                        <CountTo class="value" :startVal='0' :endVal='value2 | unitConvert_val' :duration="2000" :decimals="2" />
                        <div class="unit">{{value2 | unitConvert_unit}}</div>
                        <div class="name">{{ $t("rightBottomData.text2") }}</div>
                    </div>
                    <div class="item">
                        <CountTo class="value" :startVal='0' :endVal='value3 | unitConvert_val' :duration="2000" :decimals="2" />
                        <div class="unit">{{value3 | unitConvert_unit}}</div>
                        <div class="name">{{ $t("rightBottomData.text3") }}</div>
                    </div>
                </div>
            </div>
            <div class="chart" ref="echarts"></div>
        </div>
    </div>
</template>

<script>
import CountTo from "vue-count-to";

export default {
    props: ["child"],
    data() {
        return {
            maxVal:0,
            chart:null,
            value1:0,
            value2:0,
            value3:0,
            colors: ["#00d0e4", "#3b73d6"],
            colorTop: ["#4e95ff","#4e95ff"],
            colorBottom:["#3258ba","#3258ba"],
            language: this.$store.state.language,
            chartData: { xAxis: ['7/1', '7/2', '7/3', '7/4', '7/5', '7/6', '7/7'],
                data: [
                    {
                        name: '充电次数',
                        list: [0,0,0,0,0,0,0]
                    },
                    {
                        name: '充电量',
                        list: [0,0,0,0,0,0,0]
                    },
                    
                ]
            },
            legend:this.$t("rightBottomData.chartLegend")
        };
    },
    created() { },
    components: {
        CountTo,
    },
    mounted() {
        // this.$nextTick(() => {
        //     setTimeout(() => {
        //         this.chart ? this.chart.resize() : "";
        //         this.chart ? this.chart.clear() : "";
        //         this.init();
        //     }, 200);
        // });
        window.addEventListener("resize", () => {
            setTimeout(() => {
                this.chart ? this.chart.resize() : "";
                this.chart ? this.chart.clear() : "";
                this.init();
            }, 300);
        });
    },
    filters: {

    },
    methods: {
        dataFormat(data) {
            let da = this.chartData;
            da.xAxis = [];
            da.data[0].list = [];
            da.data[1].list = [];
            data.forEach((item,index) => {
                da.xAxis.push(item.time)
                da.data[0].list.push(item.count)
                da.data[1].list.push(item.charging)

                // 比较
                this.maxVal = this.maxVal > item.charging ? this.maxVal : item.charging;
            })
            this.init();
        },
        init() {
            let that = this;
            this.chart = this.$echarts.init(this.$refs.echarts);
            let da = that.chartData;
            let series = [], legend = [], yAxis = [];
            da.data.forEach((item,index) => {
                legend.push({
                    name :that.legend[index],
                    itemStyle:{
                        color:that.colors[index]
                    },
                })
            });

            let option = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'line',
                        lineStyle:{
                            type:"solid"
                        }
                    },
                    borderWidth:0,
                    padding:0,
                    backgroundColor:"transparent",
                    formatter:(params)=>{
                        let html = ''
                        params.forEach((item,index) => {
                            html+=`<div style="display: flex;justify-content: space-between;align-items: baseline;margin:${that.$utils.fontSize(10)}px 0;">
                                <div>
                                    <i style="display: inline-block;vertical-align: middle;width:${that.$utils.fontSize(10)}px;height:${that.$utils.fontSize(10)}px;background-color:${that.colors[index]}"></i>
                                    <span style="font-size:${that.$utils.fontSize(12)}px;color:#383e48;">${item.seriesName}</span>
                                </div>
                                <span style="font-size:${that.$utils.fontSize(14)}px;color:#006241;">${that.$options.filters.unitConvert_val(item.value)} ${item.seriesType == 'line' ? '' : that.$options.filters.unitConvert_unit(item.value)}</span>
                            </div>` 
                        })
                        return `<div style="width:${that.$utils.fontSize(200)}px;background:rgba(255,255,255,0.95);padding:${that.$utils.fontSize(10)}px;padding-bottom:${that.$utils.fontSize(10)}px;">
                            <div style="font-size:${that.$utils.fontSize(14)}px;color:#383e48;">${params[0].axisValueLabel}</div>
                            ${html}</div>`
                    }
                },
                legend:{
                    itemWidth:that.$utils.fontSize(10),
                    itemHeight:that.$utils.fontSize(10),
                    top:that.$utils.fontSize(11),
                    right:that.$utils.fontSize(40),
                    textStyle:{
                        color:"#DFE9F3",
                        fontSize:that.$utils.fontSize(14),
                    },
                    icon:"rect",
                    data:legend,
                },
                grid: {
                    left: that.$utils.fontSize(10),
                    right: that.$utils.fontSize(10),
                    bottom: that.$utils.fontSize(10),
                    top:that.$utils.fontSize(60),
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    axisLine:{
                        show:false,
                        lineStyle:{
                            color:'#0d7854',
                            width:that.$utils.fontSize(2),
                            fontFamily: 'SourceHanSansCN-Regular',
                        }
                        
                    },
                    axisTick:{
                        show:false,
                    },
                    axisLabel:{
                        color:"##99ADBF",
                        fontSize:that.$utils.fontSize(14),
                        interval:0,
                        formatter:function (value, index) {
                            return value.substr(5,5)
                        }
                    },
                    data: da.xAxis
                },
                yAxis: [
                    {
                        type: 'value',
                        name:'kWh',
                        splitNumber:3,
                        nameGap:that.$utils.fontSize(30),
                        nameTextStyle:{
                            color :"#99ADBF",
                            fontSize:that.$utils.fontSize(12),
                            fontWeight:"normal",
                            padding: [0, that.$utils.fontSize(40), 0, 0],
                        },
                        alignTicks: true,
                        axisLine:{
                            show:false,
                            lineStyle:{
                                color:'#0d7854',
                                width:that.$utils.fontSize(1),
                                fontFamily: 'SourceHanSansCN-Regular',
                            }
                            
                        },
                        splitLine:{
                            show:true,
                            lineStyle:{
                                color:"#2C3D51",
                                type:"solid",
                                dashOffset:that.$utils.fontSize(200),
                                width:that.$utils.fontSize(1),
                            }
                        },
                        axisLabel:{
                            color:"##99ADBF",
                            fontSize:that.$utils.fontSize(12),
                            formatter:(value)=>{
                                let v = 0 ;
                                if(that.maxVal < 10000){
                                    v = value
                                }else if(that.maxVal < 10000000){
                                    v = parseFloat((value / 1000).toFixed(2))
                                }else{
                                    v = parseFloat((value / 1000000).toFixed(2))
                                }
                                return v
                            }
                        }
                    },
                    {
                        type: 'value',
                        // name:'%',
                        splitNumber:3,
                        nameGap:that.$utils.fontSize(30),
                        nameTextStyle:{
                            color :"#99ADBF",
                            fontSize:that.$utils.fontSize(12),
                            fontWeight:"normal",
                            padding: [0, 0, 0, that.$utils.fontSize(30)],
                        },
                        alignTicks: true,
                        axisLine:{
                            show:false,
                            lineStyle:{
                                color:'#0d7854',
                                width:that.$utils.fontSize(1),
                                fontFamily: 'SourceHanSansCN-Regular',
                            }
                            
                        },
                        splitLine:{
                            show:true,
                            lineStyle:{
                                color:"#2C3D51",
                                type:"solid",
                                dashOffset:that.$utils.fontSize(200),
                                width:that.$utils.fontSize(1),
                            }
                        },
                        axisLabel:{
                            color:"##99ADBF",
                            fontSize:that.$utils.fontSize(12),
                            // formatter:(value)=>{
                            //     return value / 1000 +'k'
                            // }
                        }
                    }
                ],
                series: [
                    {
                        type: 'line',
                        name:that.legend[0],
                        barWidth:that.$utils.fontSize(8),
                        yAxisIndex:1,
                        smooth:true,
                        showSymbol:false,
                        lineStyle:{
                            color: that.colors[0],
                            width:that.$utils.fontSize(2),
                        },
                        itemStyle:{
                            color: '#eeb300',
                        },
                        areaStyle:{
                            color: {
                                type: 'linear',
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [{
                                    offset: 0, color: "rgba(0,234,255,0.5)" // 0% 处的颜色
                                }, {
                                    offset: 1, color: "rgba(0,234,255,0)" // 100% 处的颜色
                                }],
                                global: false // 缺省为 false
                            },
                        },
                        data: da.data[0].list,
                    },
                    {
                        type: 'bar',
                        name:that.legend[1],
                        barWidth:that.$utils.fontSize(8),
                        yAxisIndex:0,
                        itemStyle:{
                            color: {
                                type: 'linear',
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [{
                                    offset: 0, color: that.colorTop[1] // 0% 处的颜色
                                }, {
                                    offset: 1, color: that.colorBottom[1] // 100% 处的颜色
                                }],
                                global: false // 缺省为 false
                            },
                        },
                        data: da.data[1].list,
                    },
                ]
            };
            that.chart.setOption(option, true);
        },
    },
    watch: {
        "$store.state.language": function (newLang) {
            this.language = newLang;
            this.legend = this.$t("rightBottomData.chartLegend")
            this.$nextTick(() => {
                this.init();
            });
        },
        child: {
            handler: function (newValue) {
                this.$nextTick(() => {
                    this.value1 = parseFloat(newValue.value1);
                    this.value2 = parseFloat(newValue.value2);
                    this.value3 = parseFloat(newValue.value3);
                    this.dataFormat(newValue.chartData);
                });
            },
            deep: true,
        },
    },
};
</script>

<style scoped lang="scss">
.page-item{
    flex:1;
    background:url('../assets/images/item-bg5.png') no-repeat;
    background-size: 100% 100%;

    .item-content {
        position: relative;

        .item-info{
            ._top{
                height:100% !important;
                border-bottom:none !important;
            }
        }
        .chart{
            height:70%;
        }
    }
}

</style>
