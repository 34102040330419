import instance from './request'
import store from "../store/index";
//场站列表
export function getToken() {
    return instance({
        // url:'nikeclc-api/home' ,
        url: 'monitor/user/login',
        method: 'post',
        data: {
            username: 'hsd',
            password: '7zV3kD2FhTVpjw',
        }
    })
}

//场站列表
export function get_site() {
    return instance({
        url: 'monitor/site/list',
        method: 'get',
    })
}

//场站概况
export function site_overview() {
    return instance({
        url: 'monitor/site/overview?siteId='+store.state.siteId,
        method: 'get',
    })
}

// 储能概况
export function ess_overview() {
    return instance({
        url: 'monitor/ess/overview?siteId='+store.state.siteId,
        method: 'get',
    })
}

// 光伏概况
export function solar_overview() {
    return instance({
        url: 'monitor/solar/overview?siteId='+store.state.siteId,
        method: 'get',
    })
}

// 节能减排
export function reduction_overview() {
    return instance({
        url: 'monitor/reduction/overview?siteId='+store.state.siteId,
        method: 'get',
    })
}

// 收益概况
export function revenue_overview() {
    return instance({
        url: 'monitor/revenue/overview?siteId='+store.state.siteId,
        method: 'get',
    })
}

// 充电桩概况
export function evCharger_overview() {
    return instance({
        url: 'monitor/evCharger/overview?siteId='+store.state.siteId,
        method: 'get',
    })
}